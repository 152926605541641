

















import moment from "moment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class EventHeader extends Vue {
  @Prop() private item!: any;
  @Prop() private seatsavailable!: any;

  toDate(date: any) {
    return moment(date).format("dddd, DD.MM.YYYY");
  }

  toDate2(date: any) {
    return moment(date).format("YYYY-MM-DD");
  }

}
