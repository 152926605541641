import { AxiosResponse } from "axios";
import http from "../http-common";
import * as publicIp from 'public-ip';

class UserDataService {

  protected url: string = "/user";

  protected ip: string = "";

  constructor() {
    publicIp.v4().then((ip) => this.ip = ip);
  }

  login(username: string, password: string) {
    let token = btoa(`${username}:${password}`);
    return new Promise<AxiosResponse>((resolve, reject) => http.get(`${this.url}/login`, {
      headers: {
        Authorization: `Basic ${token}`
      },
    }).then((response) => {
      localStorage.setItem(
        "org_token",
        "Basic " + btoa(`${username}:${password}`)
      );
      localStorage.setItem("org_user", response.data.username);
      localStorage.setItem("org_roles", response.data.roles.join(","));
      localStorage.setItem("org_settings", response.data.email && response.data.email.length > 0 ? "true" : "false");
      resolve(response);
    }).catch(error => reject(error)));
  }

  logout() {
    localStorage.removeItem("org_token");
    localStorage.removeItem("org_user");
    localStorage.removeItem("org_roles");
    localStorage.removeItem("org_settings");
  }

  user() {
    return http.get(`${this.url}/settings`,
      { headers: { Authorization: this.token() } });
  }

  getAll(title: string, page: number = 0, size: number = 0, sort: string = "", params: any = {}) {
    if (size === 0) {
      return http.get(`${this.url}`,
        {
          headers: { Authorization: this.token() },
          params: Object.assign({ title }, params),
        });
    } else {
      return http.get(`${this.url}`,
        {
          headers: { Authorization: this.token() },
          params: Object.assign({ search: title, page: page, size: size, sort: sort }, params),
        });
    }
  }

  save(user: any) {
    return new Promise<AxiosResponse>((resolve, reject) => http.put(`${this.url}/settings`, user, {
      headers: {
        Authorization: this.token()
      },
    }).then((response) => {
      localStorage.setItem("org_settings", response.data.email && response.data.firstName && response.data.lastName ? "true" : "false");
      resolve(response);
    }).catch(error => reject(error)));
  }

  create(user: any) {
    return http.post(`${this.url}`, user,
      { headers: { Authorization: this.token() } });
  }

  createAccount(user: any) {
    return http.post(`${this.url}/create`, user, { headers: { ipAddress: this.ip } });
  }

  enableAccount(token: string) {
    return http.post(`${this.url}/enableaccount`, { token }, { headers: { ipAddress: this.ip } });
  }

  userGuests() {
    return http.get(`${this.url}/guests`, 
      { headers: { Authorization: this.token() } });
  }

  update(user: any) {
    return http.put(`${this.url}`, user,
      { headers: { Authorization: this.token() } });
  }

  delete(user: any) {
    return http.delete(`${this.url}/${user.username}`,
      { headers: { Authorization: this.token() } });
  }

  reset(user: any) {
    return http.post(`${this.url}/reset`, user,
      { headers: { ipAddress: this.ip } });
  }

  newpassword(token: string, password: string) {
    return http.post(`${this.url}/newpassword`, { token: token, password: password },
      { headers: { ipAddress: this.ip } });
  }

  isLoggedIn() {
    return localStorage.getItem("org_token") || false;
  }

  username() {
    return localStorage.getItem("org_user");
  }

  getIp() {
    return this.ip;
  }

  hasSettings(): boolean {
    return localStorage.getItem("org_settings") === "true";
  }

  token() {
    return localStorage.getItem("org_token");
  }

  hasRoles(roles: string[]) {
    if (roles.length === 0) {
      return true;
    }

    if (roles.length === 1 && roles[0] === "ROLE") {
      return true;
    }

    let r = localStorage.getItem("org_roles");

    if (r) {
      return r.split(",").filter(value => roles.includes(value)).length > 0;
    }

    return false;
  }

  services() {
    return http.get(`${this.url}/services`, { headers: { Authorization: this.token() } });
  }

}

export default new UserDataService();

