









































































































































import { Component, Vue } from "vue-property-decorator";
import AuthService from "./services/AuthService";
import router from "./router/index";

@Component
export default class App extends Vue {
  private menu: any[] = [
    { label: "Home", route: "home", icon: "house-door" },
    {
      label: "Dienstplan",
      route: "userroster",
      icon: "table",
      role: ["ROLE_STAFF"],
    },
    {
      label: "Dienstplan",
      icon: "table",
      role: ["ROLE_ADMIN", "ROLE_OFFICE"],
      submenu: [
        { label: "Verwaltung", route: "roster", icon: "table" },
        {
          label: "Meine Dienste",
          route: "userroster",
          icon: "person-bounding-box",
        },
      ],
    },
    {
      label: "Meine Registrierungen",
      route: "request",
      icon: "person-bounding-box",
      loggedin: false,
    },
    { label: "Termine", route: "event", icon: "calendar" },
    { label: "Orte", route: "location", icon: "info-square" },
    { label: "Mitarbeiter", route: "staff", icon: "people" },
    {
      label: "Administration",
      icon: "tools",
      submenu: [
        { label: "Benutzer", route: "user", icon: "at" },
        { label: "Suche", route: "search", icon: "search" },
        { label: "QR Codes", route: "qrcode", icon: "upc-scan" },
        { label: "Statistiken", route: "statistics", icon: "bar-chart" },
        { label: "Logs", route: "activitylog", icon: "clock-history" },
      ],
    },
  ];

  allowed(menu: any, flatten: boolean = false) {
    return menu
      .flatMap((m: any) => (m.submenu && flatten ? m.submenu : [m]))
      .filter((l: any) => this.isMenuAllowed(l));
  }

  isMenuAllowed(menu: any) {
    if (menu.role) {
      return AuthService.isLoggedIn() && AuthService.hasRoles(menu.role);
    } else if (menu.submenu && menu.submenu.length > 0) {
      return menu.submenu.filter((l: any) => this.isMenuAllowed(l)).length > 0;
    } else {
      return (
        menu.loggedin === false ||
        (AuthService.isLoggedIn() &&
          AuthService.hasRoles(
            router.resolve({ name: menu.route }).route.meta.auth
          ))
      );
    }
  }

  isLoggedIn() {
    return AuthService.isLoggedIn();
  }

  username() {
    return AuthService.username();
  }

  hasSettings() {
    return AuthService.hasSettings();
  }
}
