










import moment from "moment";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class MonthSelector extends Vue {
  @Prop() private month!: string;
  private selectedmonth: string = "";

  months() {
    return [...Array(4).keys()]
      .map((i) => {
        var currentTime = new Date();
        currentTime.setDate(1);
        currentTime.setMonth(currentTime.getMonth() + i);
        return currentTime;
      })
      .map((d) => <any>{ value: this.toDate2(d), text: this.toDate(d) });
  }

  @Emit()
  change(event: any) {
  }

  toDate(date: any) {
    return moment(date).format("MMMM YYYY");
  }

  toDate2(date: any) {
    return moment(date).format("YYYY-MM-DD");
  }

  mounted() {
    this.selectedmonth = this.month || this.toDate2(new Date().setDate(1));
  }
}
