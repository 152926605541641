import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import { TablePlugin } from 'bootstrap-vue'
import { CardPlugin } from 'bootstrap-vue'
import { SidebarPlugin } from 'bootstrap-vue'
import { FormPlugin } from 'bootstrap-vue'
import { FormTextareaPlugin } from 'bootstrap-vue'
import { FormDatepickerPlugin } from 'bootstrap-vue'
import { FormSelectPlugin } from 'bootstrap-vue'
import { FormInputPlugin } from 'bootstrap-vue'
import { FormGroupPlugin } from 'bootstrap-vue'
import { FormCheckboxPlugin } from 'bootstrap-vue'
import { FormTagsPlugin } from 'bootstrap-vue'
import { FormRadioPlugin } from 'bootstrap-vue'
import { ListGroupPlugin } from 'bootstrap-vue'
import { ButtonPlugin } from 'bootstrap-vue'
import { PaginationPlugin } from 'bootstrap-vue'
import { ModalPlugin } from 'bootstrap-vue'
import { AlertPlugin } from 'bootstrap-vue'
import { ProgressPlugin } from 'bootstrap-vue'
import { BadgePlugin } from 'bootstrap-vue'
import { VBTogglePlugin } from 'bootstrap-vue'
import { TooltipPlugin } from 'bootstrap-vue'
import { Multiselect } from 'vue-multiselect'

import EventHeader from './components/EventHeader.vue';
import MonthSelector from './components/MonthSelector.vue';

import VueMoment from 'vue-moment'
import LineChart from "./charts/LineChart.vue";
import BarChart from "./charts/BarChart.vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";

Vue.use(CKEditor);
Vue.use(TablePlugin)
Vue.use(CardPlugin)
Vue.use(SidebarPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(VueMoment)
Vue.use(FormPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormTagsPlugin)
Vue.use(FormRadioPlugin)
Vue.use(ButtonPlugin)
Vue.use(PaginationPlugin)
Vue.use(ModalPlugin)
Vue.use(AlertPlugin)
Vue.use(ProgressPlugin)
Vue.use(ListGroupPlugin)
Vue.use(BadgePlugin)
Vue.use(VBTogglePlugin)
Vue.use(TooltipPlugin)
Vue.component("multiselect", Multiselect)
Vue.component("line-chart", LineChart)
Vue.component("bar-chart", BarChart)
Vue.component("event-header", EventHeader)
Vue.component("select-month", MonthSelector)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.mixin({
  methods: {
    editorConfig: function () {
      return {
        toolbar: [
          'bold',
          'italic',
          '|',
          'numberedList',
          'bulletedList',
          'outdent',
          'indent',
          '|',
          'link',
          '|',
          'undo',
          'redo',
        ],
      };
    },
  },
});


Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");


