import AuthService from "@/services/AuthService";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "*",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../components/Home.vue"),
    meta: {
      auth: []
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../components/Login.vue")
  },
  {
    path: "/createaccount",
    name: "createaccount",
    component: () => import("../components/CreateAccount.vue")
  },
  {
    path: "/createaccount/:token",
    name: "createaccount-token",
    component: () => import("../components/CreateAccount.vue")
  },
  {
    path: "/resetpassword",
    name: "resetpassword",
    component: () => import("../components/ResetPassword.vue")
  },
  {
    path: "/resetpassword/:token",
    name: "resetpassword-token",
    component: () => import("../components/ResetPassword.vue")
  },
  {
    path: "/error",
    name: "error",
    component: () => import("../components/Error.vue")
  },
  {
    path: "/logout",
    name: "logout",
  },
  {
    path: "/userroster",
    name: "userroster",
    component: () => import("../components/UserRoster.vue"),
    meta: {
      auth: ["ROLE_OFFICE", "ROLE_ADMIN", "ROLE_STAFF"]
    }
  },
  {
    path: "/roster",
    name: "roster",
    component: () => import("../components/Roster.vue"),
    meta: {
      auth: ["ROLE_OFFICE", "ROLE_ADMIN"]
    }
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../components/Settings.vue"),
    meta: {
      auth: ["ROLE"]
    }
  },
  {
    path: "/statistics",
    name: "statistics",
    component: () => import("../components/Statistics.vue"),
    meta: {
      auth: ["ROLE_ADMIN"]
    }
  },
  {
    path: "/activitylog",
    name: "activitylog",
    component: () => import("../components/ActivityLog.vue"),
    meta: {
      auth: ["ROLE_ADMIN"]
    }
  },
  {
    path: "/qrcode",
    name: "qrcode",
    component: () => import("../components/QrCode.vue"),
    meta: {
      auth: ["ROLE_ADMIN"]
    }
  },
  {
    path: "/event",
    name: "event",
    component: () => import("../components/Event.vue"),
    meta: {
      auth: ["ROLE_ADMIN"]
    }
  },
  {
    path: "/staff",
    name: "staff",
    component: () => import("../components/Staff.vue"),
    meta: {
      auth: ["ROLE_ADMIN"]
    }
  },
  {
    path: "/location",
    name: "location",
    component: () => import("../components/Location.vue"),
    meta: {
      auth: ["ROLE_ADMIN"]
    }
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../components/User.vue"),
    meta: {
      auth: ["ROLE_ADMIN", "ROLE_OFFICE"]
    }
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../components/Search.vue"),
    meta: {
      auth: ["ROLE_ADMIN", "ROLE_OFFICE"]
    }
  },
  {
    path: "/registration",
    name: "registration",
    component: () => import("../components/Registration.vue"),
    meta: {
      auth: []
    }
  },
  {
    path: "/registration/:event",
    name: "registration-event",
    component: () => import("../components/Registration.vue"),
    meta: {
      auth: []
    }
  },
  {
    path: "/edit/:token",
    name: "edit",
    component: () => import("../components/Registration.vue"),
    meta: {
      auth: []
    }
  },
  {
    path: "/request",
    name: "request",
    component: () => import("../components/Request.vue"),
    meta: {
      auth: []
    }
  },
  {
    path: "/report/:event",
    name: "report",
    component: () => import("../components/Report.vue"),
    meta: {
      auth: ["ROLE_ADMIN", "ROLE_OFFICE"]
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name === "logout") {
    AuthService.logout();
    window.location.reload();
    next({
      path: '/home',
    })
  } else if (to.name === "login") {
    next()
  } else if (to.matched.some(record => record.meta.auth?.length > 0)) {
    if (!AuthService.isLoggedIn()) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else if (!AuthService.hasRoles(to.meta.auth)) {
      next({
        path: '/error',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;